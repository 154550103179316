<template>
  <component :is="iconComponent" :class="iconClass" />
</template>

<script>
import AlertCircle from 'vue-material-design-icons/AlertOutline.vue';


export default {
  components: {
    AlertCircle,
  },
  props: {
    createdAt: {
      type: String,
    },
    deliveredAt: {
      type: String,
    },
    readAt: {
      type: String,
    },
    errorCode: {
      type: [String, null],
    },
    typeUser: {
      type: String,
    },
  },
  computed: {
    iconComponent() {
      if (this.createdAt) {
        if (this.deliveredAt) {
          return 'check-all-icon';
        }
        if (this.errorCode && this.typeUser === 'User') {
          return 'alert-circle';
        }
        return 'check-icon';
      }
      return 'clock-outline-icon';
    },
    iconClass() {
      return [
        'message__footer-icon',
        {
          'message__footer-icon--is-read': this.readAt,
          'message__footer-icon--squared': !this.createdAt,
          'message__footer-icon-error': this.errorCode,
        },
      ];
    },
  },
};
</script>

<style scoped lang="scss">
  @import '~styles/components/chat/_message.scss';
</style>
